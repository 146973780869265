// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import esLocale from '@fullcalendar/core/locales/pt-br';
import DataTable from "datatables.net-bs5";
import TomSelect from "tom-select";
import IMask from "imask";
import Inputmask from "inputmask";
import Dropzone from 'dropzone';
import Bs5Utils from 'bs5-utils';

window.bootstrap = bootstrap;
window.DataTable = DataTable();
window.TomSelect = TomSelect;
window.IMask = IMask;
window.Inputmask = Inputmask;
window.Dropzone = Dropzone;
window.bs5Utils = new Bs5Utils();

// show snackbar examples
// window.bs5Utils.Snack.show('success', 'sucess!', delay = 10000, dismissible = true);
// window.bs5Utils.Snack.show('primary', 'primary!', delay = 10000, dismissible = true);
// window.bs5Utils.Snack.show('secondary', 'secondary!', delay = 10000, dismissible = true);
// window.bs5Utils.Snack.show('danger', 'erro!', delay = 10000, dismissible = true);


// Ativa Menu Lateral
document.querySelector('#navbarSideCollapse').addEventListener('click', function () {
  document.querySelector('.offcanvas-collapse').classList.toggle('open')
});

// Ativa os tooltips

function startTooltips(){
  if(window.bootstrap){
    initializeTooltips();
  } else {
    setTimeout(() => {startTooltips();}, 200);
  }
}

initializeTooltips = () => {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  var openedTooltip = [].slice.call(document.querySelectorAll('.tooltip.show'));
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  });
  var openedTooltipList = openedTooltip.map(function (opened) {
    opened.classList.remove('show');
  });
}

startTooltips();

// select2 - Tom Select
// select2 - Tom Select - default
// document.addEventListener('turbolinks:load', function() {

function startSelects(){
  if(window.TomSelect){
    initializeSelectsApp();
  } else {
    setTimeout(() => {startSelects();}, 200);
  }
}

function initializeSelectsApp(){
  var selects = document.getElementsByClassName('form-select');
  var array = [].slice.call(selects);

  array.forEach(element => {
    if(!element.classList.contains('tomselected') && element.id){
      let tomselect = new TomSelect("#" + element.id,{
        create: false,
        allowEmptyOption: true,
        // plugins: [''],
        sortField: {
          field: "nome",
          direction: "asc"
        },
        plugins: ['dropdown_input', 'remove_button', {
          remove_button:{
            title:'Remover item',
          }
        }],
      });
      tomselect;
    }
  });

  var selects_with_create = document.getElementsByClassName('form-select-with-create');
  var array_wc = [].slice.call(selects_with_create);

  array_wc.forEach(element => {
    if(!element.classList.contains('tomselected') && element.id){
      let tomselect = new TomSelect("#" + element.id,{
        plugins: ['no_active_items'],
        persist: false,
	      create: true
      });
      tomselect;
    }
  });


  var multiselects = document.getElementsByClassName('form-multiselect');
  var array2 = [].slice.call(multiselects);

  array2.forEach(element => {
    if(!element.classList.contains('tomselected') && element.id){
      let tomselect = new TomSelect("#" + element.id,{
        plugins: {
          remove_button:{
            title:'Remover item',
          }
        },
        persist: false,
        create: false,
        onChange: function() {
          if(document.querySelector("#"+element.id).classList.contains('tomselected')){
            document.querySelector("#"+element.id).tomselect.close();
          }
        },
        // onDelete: function(values) {
        //   return confirm(values.length > 1 ? 'Are you sure you want to remove these ' + values.length + ' items?' : 'Are you sure you want to remove "' + values[0] + '"?');
        // }
      });
      tomselect;
    }
  });

}

startSelects();
// });




// Autoclose msgs
window.setTimeout(function() {
  var alertList = document.querySelectorAll('.alert .btn-close')
  alertList.forEach(function (alert) {
      // new bootstrap.Alert(alert)
      alert.click();
  });
}, 3000);

document.addEventListener('DOMContentLoaded', function() {
  let calendarEl = document.getElementById('calendar');
  if (calendarEl) {
    let calendar = new Calendar(calendarEl, {
      locale: esLocale,
      plugins: [ dayGridPlugin, timeGridPlugin, listPlugin, bootstrap5Plugin ],
      themeSystem: 'bootstrap5',
      initialView: 'dayGridMonth',
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,listWeek'
      }
    });

    setTimeout(() => {
      calendar.render();
    }, 1000);
  }

  // calendar daygrid
  let calendarEl2 = document.getElementById('calendar-daygrid');
  if (calendarEl2) {
    let calendar2 = new Calendar(calendarEl2, {
      locale: esLocale,
      themeSystem: 'bootstrap5',
      plugins: [ dayGridPlugin, timeGridPlugin, listPlugin, bootstrap5Plugin ],
      initialView: 'dayGridWeek',
      headerToolbar: {
        left: 'prev,next',
        center: 'title',
        right: 'dayGridDay,dayGridWeek,dayGridMonth'
      },
      editable: true,
      events: {
        url: window.location.href,
        extraParams: {
          format: 'json'
        }
      },
      eventClick: function(info) {
        let url = info.event.extendedProps.table_name
        $.ajax({
          url: "/" + url + "/" + info.event.id,
          type: 'GET',
          dataType: 'script',
          success: function(response){
          },
          error: function(error){
          }
        });
      }
    });
    setTimeout(() => {
      calendar2.render();
    }, 1000);
  }

});
